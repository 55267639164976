import React, { useCallback, useState } from 'react';
import { Grid, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import { usePopup } from '../../../../Hooks';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { UserInvitationForm } from './Form/user-invitation.form';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { userInvitationValidation } from './Form/user-invitation.validation';
import { InvitationDto } from '../../../../Types';
import { Success } from '../../../Molecules';
import APIAxios, { APIRoutes } from '../../../../API/api.axios';
import { useSnackbar } from 'notistack';
import { useStateMachine } from 'little-state-machine';
import { Popup } from '../../../../Theme';

interface UserInvitationFormPopupProps {
  email?: string;
}

export const UserInvitationFormPopup = ({ email }: UserInvitationFormPopupProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { closePopup } = usePopup('CreateInvitationPopup');
  const [success, setSuccess] = useState<boolean>(false);
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const methods = useForm<InvitationDto>({
    resolver: yupResolver(userInvitationValidation),
    mode: 'onChange',
  });
  const { isValid } = methods.formState;

  const { state: { user: { beneficiary } } } = useStateMachine();

  const submitInvitation = useCallback(
    async (data: InvitationDto) => {
      try {
        await APIAxios({
          ...APIRoutes.POSTAuthorizations(), data: {
            ...data,
          }
        })
        setSuccess(true);
      } catch (error: any) {
        switch (error.response.data.message) {
          case 'RELATION_ALREADY_EXIST':
            enqueueSnackbar(`Vous êtes déjà lié à ce ${beneficiary ? 'consultant' : 'bénéficiaire'}.`,
              { variant: 'error' });
            break
          case 'WRONG_OR_UNAUTHORIZED_INVITATION':
            enqueueSnackbar(`Erreur : un compte avec cette adresse email existe déjà. Si ceci vous paraît anormal, veuillez vous rapprocher du support. Merci.`,
              { variant: 'error' });
            break;
          case "CONSULTANT_CANT_INVITE_CONSULTANT":
            enqueueSnackbar(`Erreur : un compte consultant avec cette adresse email existe déjà. Si ceci vous paraît anormal, veuillez vous rapprocher du support. Merci.`,
              { variant: 'error' });
            break;
          // Desactivated to Allow multiple invitations
          // case "ALREADY_INVITED":
          //   enqueueSnackbar(`Une invitation a déjà été envoyée à cette adresse email.`,
          //     { variant: 'error' });
          //   break;
          default:
            enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.", { variant: 'error' });
            break;
        }
      }
    }, [enqueueSnackbar, beneficiary],
  );

  const onInvitationSubmit = (data: InvitationDto) => submitInvitation(data);

  const onSuccessClose = () => {    
    if (window.location.pathname.includes('my-invitations')) {
      window.location.reload();
    } else {
      closePopup();
    }
  };

  if (success) {
    return (
      <Popup open={true} onClose={onSuccessClose}>
        <Success title="L'invitation a été envoyée" popupId="CreateInvitationPopup" closeHandler={onSuccessClose} />;
      </Popup>
    )
  }

  return (
    <Grid container padding={isSm ? theme.spacing(2) : theme.spacing(6)} justifyContent="center">
      {/* header */}
      <Grid container justifyContent="space-between" marginBottom={4} wrap="nowrap">
        <Typography variant="h2">
          Envoyer une invitation
        </Typography>
        <Button variant="icon" color="primary" onClick={closePopup}>
          <CloseRoundedIcon />
        </Button>
      </Grid>
      {/* content */}
      <FormProvider {...methods}>
        <UserInvitationForm email={email} />
      </FormProvider>
      {/* footer */}
      <Grid item container xs={12} justifyContent="center" marginTop={4}>
        <Button variant="contained" disabled={!isValid} onClick={methods.handleSubmit(onInvitationSubmit)}>
          Envoyer
        </Button>
      </Grid>
    </Grid>
  );
};
