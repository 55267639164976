import { styled } from '@mui/material/styles';

type TCardVariant = 'generic' | 'action' | 'fixedSize' | 'fixedSizeDisabled' | undefined;
type TColor = 'primary' | 'secondary' | 'tertiary' | 'quaternary' | undefined;
// TODO => set in interfaces / type declarations
export interface IBaseCard {
  variant?: TCardVariant;
  color?: TColor;
}

export const Card = styled('div')<IBaseCard>(({ theme, variant, color }) => ({
  display: 'flex',
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  flex: '1 1 auto',
  ...(variant === 'generic' && {
    // For generic variant
    minHeight: theme.customShapes.cardHeight,
    padding: '29px',
    ...(color === undefined && {
      color: theme.palette.text.primary,
      boxShadow: theme.customShadows.shadow1,
    }),
    ...(color !== undefined && {
      color: theme.palette.background.default,
    }),
    ...(color === 'primary' && {
      backgroundColor: theme.palette.primary.main,
    }),
    ...(color === 'secondary' && {
      backgroundColor: theme.palette.secondary.main,
    }),
    ...(color === 'tertiary' && {
      backgroundColor: theme.secondaryPalette.orange,
    }),
    ...(color === 'quaternary' && {
      backgroundColor: theme.secondaryPalette.blue,
    })
  }),
  ...(variant === 'action' && {
    // For action variant
    overflow: 'hidden',
    backgroundColor: theme.secondaryPalette.beige,
    padding: theme.spacing(2, 4),
    '&::before': {
      content: '""',
      height: '100%',
      width: '6px',
      position: 'absolute',
      right: `calc(100% - 6px)`,
      top: 0,
      ...(color === 'primary' && {
        backgroundColor: theme.palette.primary.main,
      }),
      ...(color === 'secondary' && {
        backgroundColor: theme.palette.secondary.main,
      }),
      ...(color === 'tertiary' && {
        backgroundColor: theme.secondaryPalette.orange,
      }),
      ...(color === 'quaternary' && {
        backgroundColor: theme.secondaryPalette.blue,
      })
    },
  }),
  ...(variant === 'fixedSize' && {
    // For fixedSize
    overflow: 'hidden',
    maxWidth: '345px',
    padding: theme.spacing(2, 4),
    boxShadow: theme.customShadows.shadow3,
    [theme.breakpoints.down('lg')]: {
      maxWidth: '270px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  }),
  ...(variant === 'fixedSizeDisabled' && {
    // For fixedSize
    color: theme.palette.grey[400],
    '& h3, & h6': {
      color: theme.palette.grey[400],
    },
    overflow: 'hidden',
    maxWidth: '345px',
    padding: theme.spacing(2, 4),
    boxShadow: theme.customShadows.shadow3,
    [theme.breakpoints.down('lg')]: {
      maxWidth: '270px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  }),
}));
