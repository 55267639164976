import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { Badge, Button, Grid, Tooltip, Typography } from "@mui/material";

import { DataTableHeader } from "../../../Components";
import { Send } from "@mui/icons-material";
import { usePopup } from "../../../Hooks";

interface IInvitationColumns {
  setEmail?: (email: string) => void;
}

export const getInvitationsColumns = ({ setEmail }: IInvitationColumns): GridColDef[] => {
  return (
    [
      {
        field: "email",
        headerName: "Email",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 200,
        headerAlign: "left",
        align: "left",
        renderHeader: () => <DataTableHeader headerName="Email" />,
        renderCell: (params: GridCellParams) => (
          <Typography variant="body1" fontStyle='italic' className="lowercase">
            {params.row.InvitedEmail}
          </Typography>
        )
      },
      {
        field: "invitationDate",
        headerName: "Date d'invitation",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderHeader: () => <DataTableHeader headerName="Date d'invitation" />,
        renderCell: (params: GridCellParams) => (
          <Typography variant="body1">
            {params?.row.createdAt ? new Date(params.row.createdAt).toLocaleDateString() : "-"}
          </Typography>
        )
      },
      {
        field: "lastUpdate",
        headerName: "Dernière relance",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderHeader: () => <DataTableHeader headerName="Dernière relance" />,
        renderCell: (params: GridCellParams) => (
          <Typography variant="body1">
            {params?.row.updateNumber ? new Date(params.row.lastUpdate).toLocaleDateString() : "-"}
          </Typography>
        )
      },
      {
        field: "updateNumber",
        headerName: "Nombre de relances",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderHeader: () => <DataTableHeader headerName="Nombre de relances" />,
        renderCell: (params: GridCellParams) => (
          <Grid display='flex' color='primary' justifyContent='center' alignItems='center'>
            <Typography variant="body1">
              {params.row.updateNumber || 0}
            </Typography>
          </Grid>
        )
      },
      {
        field: "status",
        headerName: "Statut",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderHeader: () => <DataTableHeader headerName="Statut" />,
        renderCell: (params: GridCellParams) => {
          const { row: { acceptedInvitation: isAccepted, InvitedEmail } } = params
    
          return (
            <Tooltip
              title={isAccepted ? `${InvitedEmail} a accepté votre invitation` : isAccepted === false ? `${InvitedEmail} a refusé votre invitation` : `Invitation en attente... Vous pouvez envoyer une relance à ${InvitedEmail} en utilisant les actions du tableau.` }
              placement="top"
              arrow
            >
              <Badge variant="standard" color={isAccepted ? 'success' : isAccepted === false ? 'error' : 'secondary' } badgeContent={
                <Typography padding={1} variant='caption' fontWeight='bold' whiteSpace='nowrap'>
                  {isAccepted ? "Acceptée" : isAccepted === false ? 'Refusée' : "En attente"}
                </Typography>
              }/>
            </Tooltip>
          )
        }
      },
      {
        field: "action",
        headerName: "Action",
        sortable: false,
        disableColumnMenu: true,
        flex: 1,
        minWidth: 200,
        headerAlign: "center",
        align: "center",
        renderHeader: () => <DataTableHeader headerName="Action" />,
        renderCell: (params: GridCellParams) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const { openPopup } = usePopup('CreateInvitationPopup');
          const { row: { acceptedInvitation } } = params
    
          return (
            <Tooltip
              title={acceptedInvitation ? "L'invitation a déjà été acceptée" : "Relancer" }
              placement="top"
              arrow
            >
              <Grid>
                <Button disabled={acceptedInvitation} onClick={() => {                  
                  if (setEmail)
                    setEmail(params.row.InvitedEmail as string)

                  openPopup()
                }}>
                  <Send color='primary' />
                </Button>
              </Grid>
            </Tooltip>
          )
        }
      },
    ]
  )
} 
