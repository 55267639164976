import AbstractDataTableService from "../data-table.base.service";
import { GetActionLightDto } from "../../../Types/actions.type";

export interface IUserActionsRow {
  /* the id of the action */
  id: string;
  type: string;
  consultant: string;
  date?: Date;
  status: string;
  info: string;
}

export class UserActionsDatatableService extends AbstractDataTableService<GetActionLightDto, IUserActionsRow> {
  constructor(
    pageSize: number,
  ) {
    super(pageSize);
  }

  protected buildRow(
    action: GetActionLightDto
  ): IUserActionsRow {
    return {
      id: action.id,
      type: action.type,
      consultant: action?.consultant ? action?.consultant?.lastName?.toUpperCase() + ' ' + action?.consultant?.firstName : '-',
      date: action?.updatedAt,
      status: action?.status,
      info: action.description || '-',
    };
  }
}

export const userActionsDatatableService = new UserActionsDatatableService(10);
