import * as Yup from 'yup';

export const userInvitationValidation = Yup.object().shape(
  {
    message: Yup.string().max(800, 'Ce champ ne peut pas excéder 800 caractères.'),
    // searchUser: Yup.object()
    //   .nullable()
      // .test(
      //   'notBothAtTheSameTime',
      //   "Vous ne pouvez pas utiliser ce champ en même temps que la recherche d'e-mail",
      //   function (searchUser) {
      //     const { userMail } = this.parent;
      //     if (userMail && userMail.length && searchUser) {
      //       return false;
      //     }
      //     return true;
      //   },
      // )
      // .when(['email'], {
      //   is: (email: string) => !email,
      //   then: Yup.object().nullable().required('Un email est requis'),
      // }),
    email: Yup.string()
      .required('Un email est requis')
      .email("Le format de l'e-mail est incorrecte"),
      // .test(
      //   'notBothAtTheSameTime',
      //   "Vous ne pouvez pas utiliser ce champ en même temps que la recherche d'un membre SAMI",
      //   function (email) {
      //     // const { searchUser } = this.parent;
      //     // if (searchUser && userMail) {
      //     //   return false;
      //     // }
      //     return true;
      //   },
      // )
      // .when(['searchUser'], {
      //   is: (searchUser: never) => !searchUser,
      //   then: Yup.string()
      //     .required('Au moins un des deux champs est requis')
      //     .email("Le format de l'e-mail est incorrecte"),
      // }),
  },
  [['searchUser', 'email']],
);
