import { useCallback, useEffect, useState } from 'react';
import {
  ResponsiveContainer,
  UserInvitationFormPopup,
} from '../../Components';
import { Popup } from '../../Theme';
import { InvitationsHeader, MyInvitationsDataTable } from './components';
import { usePopup } from '../../Hooks';
import { useMediaQuery, useTheme, Stack } from '@mui/material';
import { GetBeneficiaryDto, GetInvitationsDto } from '../../Types';
import APIAxios, { APIRoutes } from '../../API/api.axios';
import { useSnackbar } from 'notistack';
import { useStateMachine } from 'little-state-machine';
import { Outlet } from 'react-router-dom';
import { DatatableSearchProvider } from '../../Providers';
import { FilterPopup } from '../../Components/Molecules/filter-popup.molecule';
import { SelectOption } from '../../Components/Molecules/organism-search.molecule';

export const MyInvitations = () => {
  const { state: { user: { consultant, admin } } } = useStateMachine();
  const { popupOpen, closePopup } = usePopup('CreateInvitationPopup');
  const { enqueueSnackbar } = useSnackbar();
  const [ email, setEmail ] = useState<string>('');
  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [invitations, setInvitations] = useState<GetInvitationsDto[] | undefined>(undefined);
  const [page, setPage] = useState(1);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState<GetBeneficiaryDto | undefined>();
  const [filterPopupOpen, setFilterPopUpOpen] = useState(false);

  const getInvitations = useCallback(
    async () => {
      if (consultant?.id) {
        try {
          const res = await APIAxios({ ...APIRoutes.GETBeneficiariesInvitations(consultant?.id) });
          if (res.data as GetInvitationsDto) {
            setInvitations(res.data);
          }
        } catch (err) {
          enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.");
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enqueueSnackbar, page]
  );  

  const getBeneficiaries = async (q?: string): Promise<SelectOption[]> => {
    return await APIAxios({ ...APIRoutes.GETBeneficiaries(1, q) })
      .then((res) => {
        if (res.data) {
          return res.data.data.map((it: GetBeneficiaryDto) => ({ label: `${it.lastName} ${it.firstName}`, value: it.id, data: it }));
        }
      })
  }

  useEffect(() => {
    if (consultant?.id)
      getInvitations();
  }, [getInvitations, consultant?.id]);

  return (
    <>
      <Popup open={popupOpen()} maxWidth="md" onClose={closePopup} transitionDuration={0} fullScreen={fullscreen}>
        <UserInvitationFormPopup email={email} />
      </Popup>

      {filterPopupOpen && (
        <FilterPopup
          title='Rechercher un bénéficiaire'
          placeholder="Nom du bénéficiaire"
          handleSelect={(value) => {
            setSelectedBeneficiary(value ? value.data : undefined);
            setFilterPopUpOpen(false);
          }}
          handleClose={() => setFilterPopUpOpen(false)}
          getOptions={getBeneficiaries}
          selectedOption={selectedBeneficiary &&
            ({ value: selectedBeneficiary.id, data: selectedBeneficiary, label: `${selectedBeneficiary.lastName} ${selectedBeneficiary.firstName}` })}
        />
      )}

      <ResponsiveContainer>
          <DatatableSearchProvider>
            <InvitationsHeader handleClickFilter={() => setFilterPopUpOpen(true)} selectedBeneficiary={`${selectedBeneficiary?.lastName} ${selectedBeneficiary?.firstName}`} isAdmin={!!admin} />
            <Stack height='100%' width='100%'>
              <MyInvitationsDataTable setEmail={setEmail} setPage={setPage} data={invitations} />
            </Stack>
          </DatatableSearchProvider>
          <Outlet></Outlet>
      </ResponsiveContainer>
    </>
  );
};
