/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useCallback, ReactNode } from 'react';

export type TPopupMode = 'add' | 'edit' | 'readonly' | undefined;
interface IPopup {
  id: string;
  isOpen: boolean;
  popupMode?: TPopupMode;
}

interface IPopupManagerContext {
  popups: IPopup[];
  handleOpen: (id: string, mode?: TPopupMode, action?: string) => void;
  handleClose: (id: string) => void;
  getPopupMode: (id: string) => any;
  isOpen: (id: string) => any;
}

export const PopupContext = React.createContext<IPopupManagerContext>({
  popups: [],
  handleOpen: () => {},
  handleClose: () => {},
  getPopupMode: () => {},
  isOpen: () => {}
});

interface IPopupProviderProps {
  children: ReactNode;
}

export const PopupProvider = ({ children }: IPopupProviderProps) => {
  const [popups, setPopups] = useState<IPopup[]>([]);

  const openPopup = useCallback((id: string, mode?: TPopupMode) => {
    // Popup has already this popup in it ?
    const pIndex = popups.findIndex(p => p.id === id);
    if (pIndex < 0) {
      setPopups([
        ...popups,
        {
          id,
          isOpen: true,
          popupMode: mode
        }
      ]);
    } else {
      const cpy = [...popups];
      cpy[pIndex].isOpen = true;
      cpy[pIndex].popupMode = mode;
      setPopups(cpy)
    }
  }, [popups]);

  const closePopup = useCallback((id: string) => {
    const popupState = popups.findIndex(p => p.id === id);
    const cpy = [...popups];
    cpy[popupState].isOpen = false;
    cpy[popupState].popupMode = undefined;
    setPopups(cpy);
  }, [popups]);

  const getPopupMode = useCallback((id: string) => {
    const popupState = popups.find(p => p.id === id);
    if (popupState) {
      return popupState.popupMode;
    }
    return undefined;
  }, [popups])

  const isOpen = useCallback(
    (id: string) => {
      const popupState = popups.find((p) => p.id === id);
      if (popupState) {
        return popupState.isOpen;
      }
      return false;
    },
    [popups]
  )

  const contextValue = {
    popups,
    handleOpen: useCallback(
      (id: string, mode?: TPopupMode) => openPopup(id, mode),
      [openPopup],
    ),
    handleClose: useCallback((id: string) => closePopup(id), [closePopup]),
    getPopupMode: useCallback((id: string) => getPopupMode(id), [getPopupMode]),
    isOpen: useCallback((id: string) => isOpen(id), [isOpen]),
  };

  return <PopupContext.Provider value={contextValue}>{children}</PopupContext.Provider>;
};
