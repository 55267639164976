import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import { useStateMachine } from 'little-state-machine';
import debounce from 'lodash/debounce';
import { userProfileService } from '../../../../../Services';
import { TUserSearch } from '../../../../../Types';
import { useFormContext, Controller } from 'react-hook-form';
import { CONSTANTS } from '../../../../../Constants';

interface UserInvitationFormProps {
  email?: string;
  message?: string;
}

export const UserInvitationForm = ({ email, message }: UserInvitationFormProps) => {
  const {
    state: {
      user: {
        // profile: { isConsultant },
        consultant: isConsultant,
        beneficiary: isBenef
      },
    },
  } = useStateMachine();
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();
  // const [searchName, setSearchName] = useState<string>('');
  // const [searchValue, setSearchValue] = useState<TUserSearch | null>(null);
  const [options, setOptions] = useState<readonly TUserSearch[]>([]);
  // const [isLoading, setLoading] = useState<boolean>(false);

  const defaultMessage = useMemo(() => {
    return CONSTANTS.DEFAULT_INVITATION_MESSAGE[isConsultant ? 'consultant' : isBenef ? 'beneficiary' : 'organism'];
  }, [isConsultant, isBenef]);

  const searchProfiles = useCallback(
    async (search: string) => {
      const options = await userProfileService.searchByName(search.toLowerCase(), !!isConsultant);
      let newOptions: TUserSearch[] = [];
      // if (searchValue) {
      //   newOptions = [searchValue];
      // }
      if (options) {
        newOptions = [...newOptions, ...options];
      }
      // setLoading(false);
      setOptions(newOptions);
    },
    [isConsultant],
  );

  const search = useMemo(() => debounce((search: string) => searchProfiles(search), 400), [searchProfiles]);

  // useEffect(() => {
  //   if (searchName === '') {
  //     setOptions(searchValue ? [searchValue] : []);
  //     return undefined;
  //   }
  //   if (!searchValue) {
  //     search(searchName);
  //   }
  // }, [searchValue, searchName, search]);

  return (
    <Grid container gap={4}>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        {/* <Grid item xs={12} sm={5} container gap={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Rechercher un membre SAMI</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="searchUser"
              control={control}
              defaultValue={null}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  sx={{
                    '&.MuiAutocomplete-root .MuiAutocomplete-endAdornment': {
                      top: 'unset !important',
                    },
                  }}
                  isOptionEqualToValue={() => true}
                  fullWidth
                  options={options}
                  noOptionsText={
                    isLoading
                      ? 'Chargement'
                      : searchName?.length
                      ? `Aucun ${isConsultant ? 'bénéficiaire' : 'consultant'} trouvé`
                      : `Entrez le nom d'un ${isConsultant ? 'bénéficiaire' : 'consultant'} pour le trouver`
                  }
                  getOptionLabel={(option) => `${option.lastname?.toUpperCase()} ${option.firstname[0]?.toUpperCase() + option.firstname?.slice(1, option.firstname?.length)}`}
                  autoComplete
                  renderOption={(props, option) => (
                    <li
                      {...props}
                      className="capital margin-left-small"
                      style={{cursor: 'pointer'}}
                      key={option?.uid}
                    >{`${option?.lastname?.toUpperCase()} ${option?.firstname}`}</li>
                  )}
                  filterSelectedOptions
                  value={value}
                  onChange={(event, newValue: TUserSearch | null) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    setSearchValue(newValue);
                    onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!errors.searchUser}
                      helperText={errors.searchUser ? errors.searchUser.message : ''}
                      fullWidth
                      placeholder="Nom du membre"
                      label="Nom du membre"
                      type="text"
                    ></TextField>
                  )}
                  onInputChange={(e, newValue) => {
                    setLoading(!!newValue?.length);
                    setSearchName(newValue);
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm="auto" container alignItems="flex-end" justifyContent="center" marginY={3}>
          - OU -
        </Grid> */}
        <Grid item xs={12} sm={5} container gap={2}>
          <Grid item xs={12}>
            <Typography variant="h4">
              {isConsultant && 'Nouveau bénéficiaire'}
              {!isConsultant && 'Adresse e-mail du consultant'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...register('email')}
              error={!!errors.userMail}
              helperText={errors.userMail ? errors.userMail.message : ''}
              fullWidth
              placeholder="Adresse e-mail"
              label="Adresse e-mail"
              type="text"
              defaultValue={email || ''}
              required
            ></TextField>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} gap={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Personnalisez votre invitation</Typography>
        </Grid>
        <Controller
          name="message"
          control={control}
          defaultValue={message || defaultMessage}
          render={({ field }) => (
            <TextField
              {...field}
              error={!!errors.message}
              helperText={errors.message ? errors.message.message : ''}
              fullWidth
              placeholder="Adresse e-mail"
              label="Adresse e-mail"
              type="text"
              multiline
              minRows={8}
            ></TextField>
          )}
        />
      </Grid>
    </Grid>
  );
};
