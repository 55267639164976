/**
 * Here your can customize Material UI default theme according to the graphic chart
 */
import React from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { createTheme } from '@mui/material';
import '@fontsource/inter';

//TODO split theme in several files
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    icon: true;
    'icon-rounded': true;
    light: true;
    filter: true;
  }
}

/**
 * Adding variables to default Material UI theme
 */
declare module '@mui/material/styles' {
  interface Theme {
    customShapes: {
      drawerWidth: string;
      topbarHeight: string;
      footerHeight: string;
      bordersWidth: string;
      fixedSize48px: string;
      cardWidth: string;
      cardHeight: string;
    };
    secondaryPalette: {
      orange: string;
      lightOrange: string;
      purple: string;
      lightGreen: string;
      yellow: string;
      lightYellow: string;
      red: string;
      lightRed: string;
      beige: string;
      lightPurple: string;
      header: string;
      inputBg: string;
      inputBorder: string;
      green2: string;
      lightGreen2: string;
      pink: string;
      lightPink: string;
      blue: string;
    };
    customShadows: {
      shadow1: string;
      shadow2: string;
      shadow3: string;
    };
  }
  interface ThemeOptions {
    customShapes?: {
      drawerWidth?: string;
      topbarHeight?: string;
      footerHeight?: string;
      bordersWidth?: string;
      fixedSize48px?: string;
      cardWidth?: string;
      cardHeight?: string;
    };
    secondaryPalette?: {
      orange?: string;
      lightOrange?: string;
      purple?: string;
      lightGreen?: string;
      yellow?: string;
      lightYellow?: string;
      red?: string;
      lightRed?: string;
      beige?: string;
      lightPurple?: string;
      header?: string;
      inputBg?: string;
      inputBorder?: string;
      blue?: string;
    };
    customShadows?: {
      shadow1?: string;
      shadow2?: string;
      shadow3?: string;
    };
  }
}

let theme = createTheme({
  palette: {
    background: {
      default: "rgb(250, 250, 250)",
    },
    primary: {
      main: '#31AFB4',
      light: '#CFF1F2',
    },
    secondary: {
      main: '#74226C',
      light: '#827684',
    },
    text: {
      primary: '#272B30',
      secondary: '#6F767E',
      disabled: '#827684',
    },
    error: {
      main: '#EE4266',
    },
  },
  secondaryPalette: {
    orange: '#FE7E50',
    lightOrange: '#ffe6de',
    purple: '#74226C',
    lightPurple: '#F2ECF3',
    lightGreen: '#ECF6F5',
    yellow: '#ed8b00',
    lightYellow: '#FFFCEB',
    red: '#972D07',
    lightRed: '#FEF0EC',
    beige: '#FAF5EE',
    header: '#2F0D38',
    inputBg: '#F4F4F4',
    inputBorder: '2px solid #9A9FA5',
    blue: '#0492bd',
  },
  customShadows: {
    shadow1: '0px 12px 66px #C7C7C76C',
    shadow2: '0px 12px 55px #C7C7C76C', // TODO add when necessar
    shadow3: '0px 12px 44px #95959529',
  },
  shape: {
    borderRadius: 12,
  },
  typography: {
    fontFamily: 'Inter',
    fontWeightBold: 700,
    fontWeightMedium: 600,
    fontWeightRegular: 500,
    fontWeightLight: 300,
    fontSize: 13,
    h1: {
      fontSize: '36px',
    },
    h2: {
      fontSize: '22px',
    },
    h3: {
      fontSize: '18px',
    },
    h4: {
      fontSize: '16px',
    },
    h5: {
      fontSize: '14px',
    },
    h6: {
      fontSize: '12px',
    },
    body2: {
      fontSize: '12px',
    },
    subtitle2: {
      fontSize: '12px',
    },
  },
  customShapes: {
    drawerWidth: '267px',
    topbarHeight: '70px',
    footerHeight: '20px',
    bordersWidth: '2px',
    fixedSize48px: '48px',
    cardWidth: '330px',
    cardHeight: '200px',
  },
});

theme = createTheme(theme, {
  typography: {
    allVariants: {
      color: theme.palette.text.primary,
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
    },
    h1: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.secondaryPalette.header,
    },
    h2: {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.secondaryPalette.header,
    },
    h3: {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.secondaryPalette.header,
    },
    h4: {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.secondaryPalette.header,
    },
    h5: {
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.secondaryPalette.header,
    },
    h6: {
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.secondaryPalette.header,
    },
    subtitle1: {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightRegular,
    },
    subtitle2: {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: theme.customShapes.fixedSize48px,
          height: theme.customShapes.fixedSize48px,
          '& > svg': {
            position: 'relative',
          },
        },
        default: {
          color: '#cacaca',
          backgroundColor: '#cacaca',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <RadioButtonUncheckedIcon />,
        checkedIcon: <CheckCircleIcon />,
      },
      styleOverrides: {
        root: {
          width: theme.customShapes.fixedSize48px,
          height: theme.customShapes.fixedSize48px,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: '1px 14px',
          backgroundColor: theme.palette.background.default,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: '15px',
          backgroundColor: theme.secondaryPalette.inputBg,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: 'off',
      },
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
          backgroundColor: theme.secondaryPalette.inputBg,
          '& .MuiInputBase-adornedStart': {
            '& img': {
              paddingRight: '12px',
            }
          },
          '& .Mui-focused': {
            backgroundColor: 'white',
          },
          '& .MuiFormLabel-filled + div': {
            '&  fieldset': {
              backgroundColor: 'white',
              zIndex: 0,
              border: theme.secondaryPalette.inputBorder,
            },
            '&:hover fieldset': {
              border: theme.secondaryPalette.inputBorder,
            },
            '& input, & svg': {
              zIndex: 1,
            },
          },
          '& label, & label.Mui-focused': {
            color: '#9A9FA5',
            lineHeight: 'unset',
          },
          '& label.Mui-error': {
            color: theme.palette.error.main,
          },
          '& .MuiOutlinedInput-root': {
            position: 'relative',
            '& > *': {
              zIndex: 1,
            },
            '& > svg': {
              margin: '0 10px 0 0',
            },
            '& fieldset': {
              border: 'none',
            },
            '&.Mui-focused fieldset': {
              border: theme.secondaryPalette.inputBorder,
            },
          },
          '& .MuiOutlinedInput-root.Mui-error': {
            '& fieldset': {
              border: `${theme.customShapes.bordersWidth} solid ${theme.palette.error.main}`,
            },
            '&.Mui-focused fieldset': {
              border: `${theme.customShapes.bordersWidth} solid ${theme.palette.error.main}`,
            },
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'icon' },
          style: {
            fontSize: '15px',
            minWidth: 'unset',
            maxWidth: '42px',
            color: theme.palette.primary.main,
            backgroundColor: theme.secondaryPalette.lightGreen,
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
            },
          },
        },
        {
          props: { variant: 'light' },
          style: {
            fontSize: '15px',
            color: theme.palette.background.default,
            backgroundColor: '#FFFFFF31',
            '&:hover': {
              backgroundColor: '#FFFFFF41',
            },
          },
        },
        {
          props: { variant: 'icon-rounded' },
          style: {
            fontSize: '15px',
            minWidth: 'unset',
            width: theme.customShapes.fixedSize48px,
            height: theme.customShapes.fixedSize48px,
            borderRadius: '50%',
            color: theme.palette.primary.main,
            backgroundColor: theme.secondaryPalette.lightGreen,
            '&:hover': {
              backgroundColor: theme.palette.primary.light,
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            fontSize: '15px',
            color: theme.palette.primary.main,
          },
        },
        {
          props: { variant: 'contained' },
          style: {
            fontSize: '15px',
          },
        },
        {
          props: { variant: 'text', color: 'secondary' },
          style: {
            fontSize: '15px',
            color: theme.palette.text.secondary,
          },
        },
        {
          props: { variant: 'text', color: 'error' },
          style: {
            fontSize: '15px',
            color: theme.palette.error.main,
          },
        },
        { props: { variant: 'light', color: 'secondary' },
          style: {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
            fontSize: '14px',
            fontWeight: '600',
          },
        },
        { props: { variant: 'light', color: 'error' },
          style: {
            color: theme.palette.error.light,
            height: '35px',
            fontSize: '11px',
            '&:hover': {
              backgroundColor: 'rgba(227, 101, 101, 0.1)',
            }
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            fontSize: '15px',
            fontWeight: theme.typography.fontWeightMedium,
            border: `${theme.customShapes.bordersWidth} solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
            '&:hover': {
              border: `${theme.customShapes.bordersWidth} solid ${theme.secondaryPalette.header}`,
              color: theme.secondaryPalette.header,
              backgroundColor: 'transparent',
            },
            '&.Mui-disabled': {
              border: `${theme.customShapes.bordersWidth} solid ${theme.secondaryPalette.inputBg}`,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'secondary' },
          style: {
            fontSize: '15px',
            fontWeight: theme.typography.fontWeightMedium,
            border: `${theme.customShapes.bordersWidth} solid #EFEFEF`,
            color: theme.palette.text.secondary,
            '&:hover': {
              border: theme.secondaryPalette.inputBorder,
              backgroundColor: 'transparent',
            },
          },
        },
        {
          props: { variant: 'filter' },
          style: {
            fontSize: theme.typography.body2,
            color: theme.palette.grey[400],
            padding: '5px 10px',
            backgroundColor: 'transparent',
            whiteSpace: 'nowrap',
            border: `1px solid ${theme.palette.grey[200]}`,
            '&.active': {
              border: `1px solid ${theme.palette.background.default}`,
            },
            '&.active.TODO': {
              color: theme.secondaryPalette.orange,
              backgroundColor: theme.secondaryPalette.lightOrange,
            },
            '&.active.DONE': {
              color: theme.palette.primary.main,
              backgroundColor: theme.secondaryPalette.lightGreen,
            },
            '&.active.PENDING': {
              color: theme.secondaryPalette.purple,
              backgroundColor: theme.secondaryPalette.lightPurple,
            },
            '&.active.ABANDONED': {
              color: theme.secondaryPalette.yellow,
              backgroundColor: theme.secondaryPalette.lightYellow,
            },
            '&.active.REFUSED': {
              color: theme.secondaryPalette.red,
              backgroundColor: theme.secondaryPalette.lightRed,
            },
            '&.active.APPROVED': {
                backgroundColor: '#F0FCED',
                color: '#66B44A'
            },
            '&.active.RECOMMENDED': {
                backgroundColor: '#F9EEF7',
                color: '#9B4085'
            }
          },
        },
      ],
      styleOverrides: {
        root: {
          boxShadow: 'none',
          paddingTop: '10px',
          paddingBottom: '10px',
          fontWeight: theme.typography.fontWeightBold,
          textTransform: 'unset',
          color: theme.palette.background.default,
          '&:hover': {
            // backgroundColor: theme.secondaryPalette.header,
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            opacity: '50%',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
          height: theme.customShapes.topbarHeight,
          boxShadow: 'none',
          backgroundColor: theme.palette.background.default,
          // paddingTop: '2rem',
          // outline: `${theme.customShapes.bordersWidth} solid ${theme.palette.divider}`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiDrawer-paperAnchorLeft': {
            borderRight: '0px solid transparent',
            paddingTop: '3rem'
          }
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          flex: 0,
          borderRadius: theme.shape.borderRadius,
          boxShadow: 'none',
          paddingTop: '10px',
          paddingBottom: '10px',
          fontWeight: theme.typography.fontWeightBold,
          textTransform: 'unset',
          fontSize: '15px',
          '&:hover': {
            // backgroundColor: theme.secondaryPalette.header,
            boxShadow: 'none',
          },
          '&.Mui-disabled': {
            opacity: '50%',
          },
          '&.active': {
            color: theme.palette.primary.main,
            backgroundColor: theme.secondaryPalette.lightGreen,
          },
          '&.active:hover': {
            backgroundColor: theme.secondaryPalette.lightGreen,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          heigth: theme.customShapes.fixedSize48px,
          maxWidth: theme.customShapes.fixedSize48px,
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          '.Mui-active': {
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightBold,
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: 31,
          height: 31,
          color: theme.secondaryPalette.inputBg,
          '& text': {
            fill: theme.palette.text.secondary,
          },
          '&.Mui-active': {
            '& text': {
              fill: 'white',
            },
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '13px',
          minWidth: '120px',
          padding: '10px',
        }
      }
    }
  },
});

export default theme;
