import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { GetBeneficiaryDashboardDto, GetConsultantDashboardDto, SuspendReason, TUserRole } from '../../Types';
import { ActionsDashboard, HeaderDashboard } from './Components';
import { useStateMachine } from 'little-state-machine';
import { Popup } from '../../Theme';
import { useError, usePopup } from '../../Hooks';
import { useLocation } from 'react-router-dom';
import { UserRegistration, ResponsiveContainer, UserInvitationFormPopup, UserInvitation } from '../../Components';
import { TContainer } from '../../Types';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import APIAxios, { APIRoutes } from '../../API/api.axios';
import { useSnackbar } from 'notistack';

export type TDashboardProps = TContainer & {
  userRole: TUserRole;
  benefDashboard?: GetBeneficiaryDashboardDto;
  consultDashboard?: GetConsultantDashboardDto;
};

export const Dashboard = ({ userRole }: TDashboardProps) => {
  const { state: from } = useLocation();
  const {
    popupOpen: isRegistrationPopupOpen,
    openPopup,
  } = usePopup('RegistrationPopup');
  const {
    popupOpen: isInvitationPopupOpen,
    closePopup: closeInvitationPopup,
    openPopup: openInvitationPopup,
  } = usePopup('InvitationPopup');
  const {
    popupOpen: isCreateInvitationPopupOpen,
    closePopup: closeCreateInvitationPopup,
  } = usePopup('CreateInvitationPopup');

  const { addError } = useError()

  const theme = useTheme();
  const fullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [benefDashboard, setBenefDashboard] = useState<GetBeneficiaryDashboardDto | undefined>(undefined);
  const [consultDashboard, setConsultDashboard] = useState<GetConsultantDashboardDto | undefined>(undefined);

  const { enqueueSnackbar } = useSnackbar();
  
  const {
    state: {
      // user: { profile },
      user: {
        beneficiary,
        consultant,
        organism
      }
    },
  } = useStateMachine();

  
  // useEffect(() => {
    /**
     * !!! When comming from login page !!!
     * Check if the user profile is complete or not
     * If not, trigger the "fill profile informations flow"
     * TODO maybe set this in the PrivateRoute ??
     */

    // if (from && profile.isComplete === false && profile.isLocked === false) {
    //   openPopup();
    // }
    // TODO check the under line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [from, profile.isComplete, profile.isLocked]);


  const getDashboard = useCallback( async () => {
    if (beneficiary) {
      try {
        const res = await APIAxios({ ...APIRoutes.GETBenefDashboard() })
        if (res.data as GetBeneficiaryDashboardDto) {
          setBenefDashboard(res.data);
        } else {
          enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.");
        }
      } catch (err) {
        enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.");
      }
    } else if (consultant) {
      try {
        const res = await APIAxios({ ...APIRoutes.GETConsultantDashboard() })
        if (res.data as GetConsultantDashboardDto) {
          setConsultDashboard(res.data);
        } else {
          enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.");
        }
      } catch (err) {
        enqueueSnackbar("Une erreur est survenue. Veuillez réessayer plus tard.");

      }
    }
  }, []);

  const getInvitations = useCallback(async () => {
    try {
      const res = await APIAxios({...APIRoutes.GETAuthorizations()})
      if (res.data.length) {
        openInvitationPopup('edit');
      }
    } catch (err) {
    }
  }, []);

  useEffect(() => {
    if (consultant && consultant.isLocked) {
      addError({ code: 'account-locked'})
    }
  }, [consultant, addError]);

  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  useEffect(() => {
    getInvitations();
  }, [getInvitations]);

  useEffect(() => {
    const formCompletion = benefDashboard?.formCompletionPercentage || consultDashboard?.formCompletionPercentage;
    if (formCompletion === undefined) {
      return
    }
    if (formCompletion < 100 && localStorage.getItem('shouldCompleteProfile') !== 'false') {
      openPopup();
      localStorage.setItem('shouldCompleteProfile', 'false');
    }
  }, [benefDashboard?.formCompletionPercentage, consultDashboard?.formCompletionPercentage, openPopup]);

  return (
    <Fragment>
      <Popup
        fullScreen={fullscreen}
        open={isRegistrationPopupOpen()}
        maxWidth="md"
        transitionDuration={0}
      >
        <UserRegistration userRole={userRole} start />
      </Popup>
      <Popup
        fullScreen={fullscreen}
        open={isCreateInvitationPopupOpen()}
        maxWidth="md"
        onClose={closeCreateInvitationPopup}
        transitionDuration={0}
      >
        <UserInvitationFormPopup />
      </Popup>
      <Popup
        fullScreen={fullscreen}
        open={isInvitationPopupOpen()}
        maxWidth="md"
        onClose={closeInvitationPopup}
        transitionDuration={0}
      >
        <UserInvitation />
      </Popup>
      <ResponsiveContainer>
        <HeaderDashboard firstname={beneficiary?.firstName || consultant?.firstName} />
        {beneficiary?.isSuspended && beneficiary.suspendReason && (
          <Stack width='100%' justifyContent='left'>
            <Typography align='left'>
              {`Votre parcours a été archivé
              le ${beneficiary.suspendDate && new Date(beneficiary.suspendDate).toISOString().split('T')[0]} 
              par ${beneficiary.suspendedBy?.firstName + ' ' + beneficiary.suspendedBy?.lastName} 
              de l'organisme ${beneficiary.suspendedBy?.organismName} 
              avec le motif ${SuspendReason.label(beneficiary.suspendReason)}`}
            </Typography>
          </Stack>
        )}
        <ActionsDashboard userRole={userRole} benefDashboard={benefDashboard} consultDashboard={consultDashboard}  />
        {/* {beneficiary && <SummaryActionsDashboard />} */}
      </ResponsiveContainer>
    </Fragment>
  );
};
