import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import PersonOutline from '@mui/icons-material/PersonOutline';
import { Stack } from '@mui/material';
import { useStateMachine } from 'little-state-machine';

import { Header } from '../../../Components';
import { useDatatableSearch, usePopup } from '../../../Hooks';

interface InvitationsHeaderProps {
  isAdmin: boolean;
  selectedBeneficiary?: string;
  handleClickFilter: () => void;
}

export const InvitationsHeader = ({ isAdmin, selectedBeneficiary, handleClickFilter }: InvitationsHeaderProps) => {
  const {
    state: {
      user: {
        consultant,
      }
    }
  } = useStateMachine();

  const { search, updateSearch } = useDatatableSearch();
  const { openPopup } = usePopup('CreateInvitationPopup');

  return (
    <Header
      title={
        <>
          <Stack direction='row' spacing={1} alignItems="center">
            {isAdmin && (<PersonOutline sx={{width: '35px', height: '35px'}} />)}
            <Typography variant="h1" component="h1">
            {isAdmin ? 'Invitations' : 'Mes invitations'}
            </Typography>
          </Stack>
          {!isAdmin && (<Typography variant="h3" color="GrayText">
            Invitation{`(s)`} à créer un parcours de projet de maintien/retour à l'emploi.
          </Typography>)}
        </>
      }
      actions={
        <>
          <Button color="primary" variant="contained" onClick={() => openPopup()} disabled={!consultant?.isPremium}>
            <AddIcon style={{ marginRight: 8 }} />
            Inviter un bénéficiaire
          </Button>
          <TextField
            value={search}
            onChange={(e) => updateSearch(e.target.value)}
            label="Rechercher un(e) bénéficiaire"
            placeholder="Rechercher un(e) bénéficiaire"
            disabled
          />
        </>
      }
    />
  );
};
