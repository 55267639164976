import { DataGrid } from "@mui/x-data-grid";

import { getInvitationsColumns } from "./my-invitations.table.utils";
import { CustomPagination } from "../../../Theme";
import { GetInvitationsDto } from "../../../Types";
import { CircularProgress, Typography } from "@mui/material";
import { PaperContainer } from "../../../Components";

interface MyInvitationsDataTableProps {
  setPage: (page: number) => void;
  setEmail?: (email: string) => void;
  data?: GetInvitationsDto[] | undefined;
}

export const MyInvitationsDataTable = ({ setPage, setEmail, data }: MyInvitationsDataTableProps) => {
  if (data === undefined) return <div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}>
    <CircularProgress />
  </div>;

  if (!data.length) return (
    <Typography variant="h3">Il semble que vous n&apos;aillez pas d&apos;invitation.</Typography>
  );

  return (
    <PaperContainer>
      <DataGrid<GetInvitationsDto>
        columns={getInvitationsColumns({ setEmail })}
        rows={data || []}
        onPageChange={setPage}
        autoHeight
        paginationMode="server"
        disableSelectionOnClick
        density="standard"
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-columnSeparator": {
            visibility: "hidden",
          },
          "&.MuiDataGrid-root": {
            border: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
            borderBottom: "1px solid #e9e9e9",
          },
          "&.MuiDataGrid-root .MuiDataGrid-footerContainer": {
            borderTop: "1px solid #e9e9e9",
          },
          "&.MuiDataGrid-root *:focus-within": {
            outline: "none",
          },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer": {
            overflow: "hidden",
            whiteSpace: "normal",
            textAlign: "center",
          },
          "&.MuiDataGrid-root .MuiDataGrid-cell": {
            overflow: "hidden",
            whiteSpace: "normal",
            textAlign: "center",
            borderBottom: "1px solid #e9e9e9",
          },
        }}
        rowCount={data.length}
        components={{
          Pagination: CustomPagination,
        }}
      />
    </PaperContainer>
  )
};
